import React from "react";
import {StyledDiv} from "../styles/style";
const Grooming= () => {
    return (
        <StyledDiv>
          <h2 className="headerCentralised">Grooming</h2>
       
        </StyledDiv>
      );
    };
    
    export default Grooming;

